@import "../../../styles/base/abstracts";

.hbs-mission-topper {
  @include grid-parent;
  @include site-max-width;
  @include topper-spacing;

  &.hbs-disable-hover {
    pointer-events: none;
  }
}

.hbs-mission-topper__content {
  @include grid-child;
  margin-bottom: $spacing-xl;
}

.hbs-mission-topper__title-wrapper {
  position: relative;
}

.hbs-mission-topper__title {
  // adding custom styles here instead of a default h1 cause the hp is special

  @include fluid-type(
    (
      500px: 36px,
      1680px: $fs-giant,
    )
  );
  font-family: $ff-sans;
  font-weight: $fw-bold;
  line-height: $lh-sm;
  letter-spacing: -0.04em;

  color: $c-theme-solid;
  line-height: $lh-sm;
  margin-bottom: $spacing-md;
  max-width: 14em;

  @include mq($bp-tablet) {
    margin-bottom: $spacing-xl;
  }

  @include mq(1680px) {
    @include giant;
    line-height: $lh-sm;
  }

  // prevent period from wrapping onto its own line
  > span > span:last-child {
    position: absolute;
  }
}

.hbs-mission-topper__has-details {
  cursor: pointer;
  letter-spacing: inherit;

  span {
    position: relative;
    text-decoration: underline;
    text-decoration-color: $c-border-light;
    text-decoration-thickness: 0.13em;
    text-underline-offset: 0.1em;
    transition-duration: 0.35s;
    transition:
      font-size 0s,
      color 0.35s,
      text-decoration-color 0.35s;
  }

  @include hover {
    span {
      text-decoration-color: $c-text;
    }
  }
}

.hbs-mission-topper__has-details--active {
  span {
    @include hover-link-styles($c-spot);
  }
}

.hbs-mission-topper__media {
  overflow: hidden;
  position: relative;

  @include mq($bp-tablet) {
    background-color: $c-theme-solid;
    padding-top: 56.25%; // 16:9
    position: relative;
  }
}

.hbs-mission-topper__media-controls {
  @include absolute(0 0 x x);
  @include margin($spacing-md $spacing-md x x);
  display: flex;
  z-index: 1;
}

.hbs-mission-topper__media-control--left,
.hbs-mission-topper__media-control--right {
  @include padding($spacing-xs);
  @include size(35px);
  align-items: center;
  background-color: $c-bg;
  border-radius: $border-radius;
  color: $c-theme-solid;
  display: flex;
  justify-content: space-around;
  transition: background-color $transition;
  outline-offset: -7px !important;

  @include mq($bp-tablet) {
    @include padding($spacing-sm);
    @include size($carousel-button-size);
    background-color: $c-bg-complementary;
  }

  @include hover {
    background-color: $c-spot;

    .hbs-icon {
      color: $c-theme-solid-inverse;
    }
  }

  .hbs-icon {
    @include size($icon-size-sm);
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

.hbs-mission-topper__media-control--left {
  transform: rotate(180deg);
}

.hbs-mission-topper__media-control--right {
  margin-left: $spacing-sm;
}

.hbs-mission-topper__media-inner {
  width: 100%;

  @include mq-reduced-motion {
    transition: opacity $t-time $t-ease-out;
    transition-delay: 0.25s;
  }

  @include mq(max, $bp-tablet) {
    background: $c-text;
    figure {
      margin: 0;
    }
  }
  @include mq($bp-tablet) {
    @include absolute(0);
    @include size(100%);
  }

  .hbs-media-asset__wrapper {
    @include absolute(0);
    @include size(100%);
  }

  figure {
    padding-top: 56.25%;
    width: 100%;

    img,
    video {
      @include absolute(0);
      @include size(100%);
      object-fit: cover;

      @include mq-reduced-motion {
        animation: hpFadeIn 1s cubic-bezier(0, 0, 0.28, 0.97) forwards;
        animation-delay: 0.2s;
        opacity: 0;
      }
    }
  }
}

.hbs-mission-topper__media-inner--fadeout {
  @include mq-reduced-motion {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0;

    @include mq($bp-tablet) {
      figure {
        img,
        video {
          animation: hpFadeOut 0.25s cubic-bezier(0.79, 0.02, 1, 1) forwards;
        }
      }

      .hbs-mission-topper__cta {
        opacity: 0;
      }
    }
  }
}

.hbs-mission-topper__cta {
  @include unlink;
  background-color: $c-bg;
  display: block;
  padding: $grid-padding-mobile * 2;
  position: relative;
  z-index: 10;
  outline-offset: -7px !important;

  a {
    text-decoration: none;
    transition: none;

    &:hover {
      color: $c-bg;
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  @include mq(max, $bp-tablet) {
    @include theme-light;
  }

  @include mq($bp-tablet) {
    @include absolute($spacing-lg x x $spacing-lg);
    @include size($col-8, auto);
    padding: $spacing-lg;

    @include mq-reduced-motion {
      animation: hpTextFadeIn $transition-sm forwards;
      animation-delay: 0.25s;
      opacity: 0;
      transition: opacity $transition-sm;
    }
  }

  @include mq($bp-desktop) {
    width: $col-5;
  }

  @include mq($bp-desktop-xl) {
    width: $col-4;
  }

  @include hover {
    color: $c-bg;
    background-color: $c-spot;
  }
}

.hbs-cta-link--link {
  a:hover {
    color: $c-bg;
  }
}

.hbs-mission-topper__cta-link {
  margin-top: $spacing-md;
}
